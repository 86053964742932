import * as Sentry from '@sentry/browser'
import React, { useEffect } from 'react'
import { signOut } from '@/auth'
import * as queryString from 'query-string'

const SignOut = () => {
  useEffect(() => {
    const { signOutId, serviceList } = queryString.parse(location.search)

    if (typeof signOutId === 'object' || typeof serviceList === 'object') {
      location.replace('/failed')
      return
    }

    const services =
      serviceList == null ? [] : decodeURIComponent(serviceList).split(',')

    signOut(services, signOutId)
  }, [])

  return <h1>Signing out now...</h1>
}

export default SignOut
